/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // store the slider in a local variable


    // tiny helper function to add breakpoints
    function getGridSize() {
        return 1;
    }
    function getGridSizePartners() {
        return (window.innerWidth < 480) ? 1 :
            (window.innerWidth < 850) ? 2 : 6;
    }

    // Breakpoints (to match up with variables.scss)
    var screenMdMin = 1024,
        screenSmMin = 850,
        screenXxsMin = 480;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                new Swiper(".adverts-inactive-swiper", {
                    breakpoints: {
                        0: {
                            slidesPerView: 1.25,
                            spaceBetween: 30
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        1250: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        }
                    }
                });

                new Swiper(".adverts-active-swiper", {
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 30
                        },
                        850: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }
                });

                new Swiper(".kit-swiper", {
                    navigation: {
                        nextEl: '.kit-next',
                        prevEl: '.kit-prev',
                    },
                    spaceBetween: 25,
                    loop: true,
                    a11y: true,
                    keyboardControl: true,
                    grabCursor: true,
                });

                if ($('#countdown-timer-container').length) {
                    var dateData = document.getElementById("countdown-timer-container").getAttribute('data-date');
                    var countDownDate = new Date(Date.parse(dateData)).getTime();
                    // Update the count down every 1 second
                    var x = setInterval(function () {
                        // Get today's date and time
                        var now = new Date().getTime();

                        // Find the distance between now and the count down date
                        var distance = countDownDate - now;

                        // Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        // Display the result in the element with id="demo"
                        document.getElementById("countdown-timer").innerHTML = "<div class='value-wrapper'><div class='value'>" + days + "</div><div class='title'>days</div></div><div class='value-wrapper'><div class='value'>" + hours + "</div><div class='title'>hours</div></div><div class='value-wrapper'><div class='value'>" + minutes + "</div><div class='title'>mins</div></div>";
                        // If the count down is finished, write some text
                        if (distance < 0) {
                            clearInterval(x);
                            document.getElementById("countdown-timer").innerHTML = "EXPIRED";
                        }
                    }, 1000);
                }

                $('.search-toggle').on('click', function () {
                    $('.custom-search-form').toggleClass('active');
                    $('.toggle-icon').toggleClass('fa-search fa-times');
                });

                $('.hamburger-menu-toggle').on('click', function () {
                    $('.hamburger-menu').toggleClass('active');
                });

                $('.hamburger-menu .close-menu .inner').on('click', function () {
                    $('.hamburger-menu').removeClass('active');
                });

                // JavaScript to be fired on all pages
                var $window = $(window),
                    upcomingSlider,
                    partnersSlider,
                    tertiaryNewsSlider;

                $('body').addClass('js');
                // Body classes added based on conditional comments.
                if ($('.isIE').length > 0) {
                    $('body').addClass('ie');
                }
                if ($('.ie8').length > 0) {
                    $('body').addClass('ie8');
                    $('.ie8.hidden').remove();
                }
                if ($('.ie9').length > 0) {
                    $('body').addClass('ie9');
                    $('.ie9.hidden').remove();
                }

                $('.person').matchHeight();

                // Strip img width and height attributes from IE8
                $('body.ie8 img').removeAttr('height').removeAttr('width');


                // For iOS autoscroll on textfield focus
                var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                //console.log(iOS);


                iniScrollToFixEls = function () {
                    // Make sure STF is off before ini (is in a short timeOut to make sure iOS triggers it on rotate).
                    setTimeout(function () {
                        $('header.banner, .wrap.container-fluid').removeAttr('style');
                        $('header.banner').trigger('detach.ScrollToFixed');
                        $('.side-bar .item .teams').trigger('detach.ScrollToFixed').css('margin-top', '');

                        if ($(window).outerWidth() > screenMdMin) {

                            $('header.banner').scrollToFixed({
                                spacerClass: 'scrollToFixed-spacer'
                            }
                                /*{
                                                        marginTop:$('.top-nav').outerHeight(),
                                                        preFixed: function() { $('.top-nav').css('position','fixed'); },
                                                        preUnfixed: function() {$('.top-nav').css('position',''); }
                                                    }*/
                            );

                            teamsBlock = $('.side-bar .item .teams').outerHeight();
                            teamNames = $('.side-bar .item .teams .team').outerHeight();
                            headerBlock = $('header.banner').outerHeight();

                            teamsMtop = (teamsBlock - teamNames) + headerBlock;

                            $('.side-bar .item .teams').scrollToFixed({
                                spacerClass: 'scrollToFixed-side-bar-spacer',
                                //limit: $('.data-visualisation').offset().top <--- doesn't work properly, don't know why
                            });
                        }
                    }, 100);
                };
                iniScrollToFixEls();

                checkHeaderMob = function () {
                    setTimeout(function () {
                        if ($(window).outerWidth() < screenMdMin + 1) {
                            $('body >.wrap.container-fluid').css('margin-top', $('header.banner').outerHeight() - parseInt($('nav.nav-primary').css('margin-bottom')));
                        } else {
                            $('body >.wrap.container-fluid').css('margin-top', '');
                        }
                    }, 255);
                };
                checkHeaderMob();

                var headerhi = $('header.banner').outerHeight();



                $('.menu-item-has-children .sub-menu').before('<a data-target=".my-dropdown" class="my-dropdown-toggle"><span class="fa fa-chevron-down"></span></a>');
                $('.my-dropdown-toggle').on('click', function () {
                    tgt = $(this).attr('data-target');
                    // console.log('tgt= '+tgt);
                    toggle = 1;
                    if (tgt === "#searchform") {
                        if (iOS === true) {
                            $(window).scrollTop(0);
                        }

                        toggle = 0;
                        searchTop = $('header.banner .top-nav').outerHeight(true) - 3;
                        searchTopAlt = $('header.banner .top-nav>.row').outerHeight(true);
                        if (searchTopAlt > searchTop) {
                            searchTop = searchTopAlt;
                        }
                        //-($('#main-nav').outerHeight()/2) ;//3+$('#main-nav').offset().top-($('#main-nav').outerHeight()/2);
                        $(tgt).css('top', searchTop);
                        setTimeout(function () { $('#searchform input[type="text"]').focus(); }, 250);
                    }
                    if (tgt === '.my-dropdown') {
                        tgt = $(this).closest('.menu-item-has-children');
                    } else {
                        tgt = $(tgt);
                    }
                    if (toggle === 1) {
                        tgt.toggleClass('open');
                    } else {
                        tgt.addClass('open');
                    }
                });

                /*$('.dropdown-toggle').on('click',function(){
                    $(this).next('ul').toggle();
                });*/

                miniHeader = function () {
                    if ($('.triggerbox').length > 0) {
                        ylimit = ($('.triggerbox').offset().top - headerhi) - $(window).scrollTop();
                        if (ylimit < -5) {
                            $('header.banner, .side-bar .item .teams').addClass('mini');
                            $("#searchform").css('top', '').removeClass('open');
                        } else {
                            if ($('header.banner').hasClass('mini')) {
                                $("#searchform").css('top', '').removeClass('open');
                                $('header.banner, .side-bar .item .teams').removeClass('mini');
                            }

                        }
                    }
                };
                miniHeader();

                // Check news panel heights
                fixHeights = function () {
                    $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', '');

                    mainNewsHeight = $('.main-news-wrapper>.news-panel:first-child').outerHeight();
                    mainNewsHeightB = $('.main-news-wrapper>.news-panel.narrow').outerHeight();

                    if (mainNewsHeight < mainNewsHeightB) {
                        mainNewsHeight = mainNewsHeightB;
                    }
                    if ($(window).outerWidth() > screenSmMin) {
                        $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', mainNewsHeight);
                    } else {
                        $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', '');
                    }


                    $('.dfps>div>div').css('min-height', '');
                    dfp = $('.dfps>div').eq(0).outerHeight();
                    dfpB = $('.dfps>div').eq(1).outerHeight();
                    dfpC = $('.dfps>div').eq(2).outerHeight();

                    if (dfp < dfpB) {
                        dfp = dfpB;
                    }
                    if (dfp < dfpC) {
                        dfp = dfpC;
                    }
                    if ($(window).outerWidth() > screenSmMin) {
                        $('.dfps>div>div').css('min-height', dfp);
                    } else {
                        $('.dfps>div>div').css('min-height', '');
                    }


                };

                // Fix padding on dfps (for sidebar).
                dfpHeightFix = function () {
                    sbWidth = $('.side-bar').outerWidth();
                    dfpWidth = 300;
                    dfpHeight = 250;
                    dfpPadding = (sbWidth - dfpWidth) / 2;
                    dfpNewHeight = 250 + (sbWidth - dfpWidth);
                    if (dfpNewHeight > dfpHeight + 50) {
                        dfpNewHeight = dfpHeight + 50;
                    }

                    $('.side-bar .dfp-wrapper').css({ 'height': dfpNewHeight });
                };


                $('#mixContainer').mixItUp();

                $('.filter').on('click', function (e) {
                    e.preventDefault();
                });

                dfpHeightFix();
                fixHeights(); // For home page news and container for 'dfps'

                $(window).resize(function () {
                    //console.log('resize');
                    iniScrollToFixEls();
                    checkHeaderMob();
                    dfpHeightFix();
                    fixHeights();

                    var gridSize = getGridSize(),
                        partnerGetGridSizePartners = getGridSizePartners();
                    if (upcomingSlider !== undefined) {
                        if (upcomingSlider.length > 0) {
                            upcomingSlider.data('flexslider').vars.minItems = gridSize;
                            upcomingSlider.data('flexslider').vars.maxItems = gridSize;
                        }
                    }
                    if (partnersSlider !== undefined) {
                        if (partnersSlider.length > 0) {
                            partnersSlider.data('flexslider').vars.minItems = partnerGetGridSizePartners;
                            partnersSlider.data('flexslider').vars.maxItems = partnerGetGridSizePartners;
                        }
                    }
                    if (tertiaryNewsSlider !== undefined) {
                        if (tertiaryNewsSlider.length > 0) {
                            tertiaryNewsSlider.data('flexslider').vars.minItems = partnerGetGridSizePartners;
                            tertiaryNewsSlider.data('flexslider').vars.maxItems = partnerGetGridSizePartners;
                        }
                    }

                    //  $("#searchform").css('top','').removeClass('open');

                });
                var waiiit = 0; // setup var to stop timeout getting set repeatedly.
                $(window).scroll(function () {
                    // $("#searchform").css('top','').removeClass('open');
                    if ($('.triggerbox').length > 0) {

                        if (waiiit !== 1) {
                            setTimeout(function () {
                                //headerhi=$('header.banner').outerHeight();

                                miniHeader();
                                waiiit = 0;
                            }, 350);
                            waiiit = 1;
                        }
                    }

                    checkHeaderMob();



                    teamsBlock = $('.side-bar .item .teams').outerHeight();
                    teamNames = $('.side-bar .item .teams .team-info').outerHeight();
                    teamNamesMargins = $('.side-bar .item .teams .team-info').outerHeight(true);
                    marg = teamNamesMargins - teamNames;
                    headerBlock = $('header.banner').outerHeight();


                    if ($('.side-bar .item .teams').hasClass('scroll-to-fixed-fixed')) {
                        $('.side-bar .item .teams.scroll-to-fixed-fixed').css('margin-top', ((teamsBlock - headerBlock) * -1) + teamNames - marg);
                    } else {
                        $('.side-bar .item .teams').css('margin-top', '');
                    }
                    if ($(window).scrollTop() < 1) {
                        $('.side-bar .item .teams').css('margin-top', '');
                    }
                });

                //$('.carousel').carousel({interval: false, wrap: false});

                var myItemWidth = $('.flexslider>ul>li:first-child').outerWidth(),
                    partnerItemWidth = $('.home .partners--home>ul>li:first-child').outerWidth(),
                    tertiaryNewsItemWidth = $('.home .tertiary-news--home>ul>li:first-child').outerWidth();


                $('.home .tertiary-news--home').flexslider({
                    animation: "slide",
                    animationLoop: true,
                    controlNav: false,
                    itemWidth: tertiaryNewsItemWidth,
                    itemMargin: 20,
                    minItems: getGridSizePartners(),
                    maxItems: getGridSizePartners(),
                    move: 1,
                    slideshow: false,
                    customDirectionNav: $(".tertiary-news-nav a")
                });
                tertiaryNewsSlider = $('.home .tertiary-news--home');

                $('.home .partners--home').flexslider({
                    animation: "slide",
                    animationLoop: true,
                    controlNav: false,
                    itemWidth: partnerItemWidth,
                    itemMargin: 20,
                    minItems: getGridSizePartners(),
                    maxItems: getGridSizePartners(),
                    move: 1,
                    slideshow: true,
                    slideshowSpeed: 6000,
                    customDirectionNav: $(".partners-nav a")
                });
                partnersSlider = $('.home .partners--home');

                $('.home .flexslider').flexslider({
                    animation: "slide",
                    animationLoop: false,
                    slideshow: false,
                    customDirectionNav: $(".match-nav a")
                });


                $('.upcoming-matches .flexslider').flexslider({
                    animation: "slide",
                    animationLoop: false,
                    itemWidth: myItemWidth,
                    itemMargin: 5,
                    minItems: getGridSize(),
                    maxItems: getGridSize(),
                    move: 1,
                    slideshow: false,
                    customDirectionNav: $(".match-nav a")
                });
                upcomingSlider = $('.upcoming-matches .flexslider');


                // Twitter widget
                if ($('#twitterBox').length > 0) {
                    el = $('#twitterBox');
                    twitterHandle = el.attr('data-handle');
                    el.append('<div id="twitterFetcher"></div>');
                    var configProfile = {
                        "profile": { "screenName": twitterHandle },
                        "domId": 'twitterFetcher',
                        "maxTweets": 20,
                        "enableLinks": true,
                        "showUser": true,
                        "showTime": true,
                        "showImages": false,
                        "showRetweet": false,
                        "lang": 'en'
                    };
                    twitterFetcher.fetch(configProfile);

                    $('#twitterFetcher').before('<div class="twitter-header"><span>Tweets</span> by <a href="http://twitter.com/' + twitterHandle + ' target="_blank"">@' + twitterHandle + '</a></div>');
                    $('#twitterFetcher').after('<div class="twitter-footer"><a href="http://twitter.com/' + twitterHandle + '" target="_blank">View on Twitter</a></div>');
                }

                function handleKitButtonClick(isNext) {
                    activeKit.addClass('removing');
                    const kitSelector = isNext ? 'next' : 'prev';
                    const nextOrPrevKit = activeKit[kitSelector]('.kit-section');

                    if (nextOrPrevKit.length > 0) {
                        activeKit = nextOrPrevKit;

                        setTimeout(function () {
                            $('.kit-section').removeClass('active removing');
                            nextOrPrevKit.addClass('active adding');
                        }, 500);

                        setTimeout(function () {
                            nextOrPrevKit.removeClass('adding');
                        }, 1000);
                    } else {
                        //Prevents action if someone spams button
                    }
                }

                if ($('.page-template-template-kit-history').length > 0) {
                    var activeKit = $('.kit-section.active');
                    const nextButton = $('.custom-kit-next.active');
                    const previousButton = $('.custom-kit-prev.active');

                    nextButton.on('click', function () {
                        handleKitButtonClick(true);
                    });

                    previousButton.on('click', function () {
                        handleKitButtonClick(false);
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'page_template_template_developments_page': {
            init: function () {
                if ($('.developments-body__gallery-slider').length > 0) {
                    var galleryNavSwiper = new Swiper(".developments-body__gallery-nav", {
                        loop: false,
                        slidesPerView: 2,
                        spaceBetween: 20,
                        freeMode: true,
                        watchSlidesProgress: true,
                        navigation: {
                            nextEl: '.developments-body__gallery-nav__next',
                            prevEl: '.developments-body__gallery-nav__prev',
                        },
                        breakpoints: {
                            850: {
                                slidesPerView: 4
                            }
                        }
                    });

                    var gallerySwiper = new Swiper(".developments-body__gallery-slider", {
                        loop: true,
                        slidesPerView: 1,
                        spaceBetween: 10,
                        thumbs: {
                            swiper: galleryNavSwiper
                        }
                    });

                    var initPhotoSwipeFromDOM = function (gallerySelector) {
                        var parseThumbnailElements = function (el) {
                            var thumbElements = el.childNodes,
                                numNodes = thumbElements.length,
                                items = [],
                                figureEl,
                                linkEl,
                                size,
                                item;

                            for (var i = 0; i < numNodes; i++) {
                                figureEl = thumbElements[i];

                                if (figureEl.nodeType !== 1) {
                                    continue;
                                }

                                linkEl = figureEl.children[0];

                                size = linkEl.getAttribute('data-size').split('x');

                                item = {
                                    src: linkEl.getAttribute('href'),
                                    w: parseInt(size[0], 10),
                                    h: parseInt(size[1], 10)
                                };

                                if (figureEl.children.length > 1) {
                                    item.title = figureEl.children[1].innerHTML;
                                }

                                if (linkEl.children.length > 0) {
                                    item.msrc = linkEl.children[0].getAttribute('src');
                                }

                                item.el = figureEl;
                                items.push(item);
                            }

                            return items;
                        };

                        var closest = function closest(el, fn) {
                            return el && (fn(el) ? el : closest(el.parentNode, fn));
                        };

                        var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
                            var pswpElement = document.querySelectorAll('.pswp')[0],
                                gallery,
                                options,
                                items;

                            items = parseThumbnailElements(galleryElement);

                            options = {
                                closeEl: true,
                                captionEl: true,
                                fullscreenEl: true,
                                zoomEl: true,
                                shareEl: false,
                                counterEl: true,
                                arrowEl: true,
                                preloaderEl: true,
                                galleryUID: galleryElement.getAttribute('data-pswp-uid'),
                                getThumbBoundsFn: function (index) {
                                    var thumbnail = items[index].el.getElementsByTagName('img')[0],
                                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                                        rect = thumbnail.getBoundingClientRect();

                                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                                }
                            };

                            if (fromURL) {
                                if (options.galleryPIDs) {
                                    for (var j = 0; j < items.length; j++) {
                                        if (items[j].pid === index) {
                                            options.index = j;
                                            break;
                                        }
                                    }
                                } else {
                                    options.index = parseInt(index, 10) - 1;
                                }
                            } else {
                                options.index = parseInt(index, 10);
                            }

                            if (isNaN(options.index)) {
                                return;
                            }

                            if (disableAnimation) {
                                options.showAnimationDuration = 0;
                            }

                            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                            gallery.init();
                        };

                        var onThumbnailsClick = function (e) {
                            e = e || window.event;
                            e.preventDefault ? e.preventDefault() : (e.returnValue = false);

                            var eTarget = e.target || e.srcElement;

                            var clickedListItem = closest(eTarget, function (el) {
                                return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
                            });

                            if (!clickedListItem) {
                                return;
                            }

                            var clickedGallery = clickedListItem.parentNode,
                                childNodes = clickedListItem.parentNode.childNodes,
                                numChildNodes = childNodes.length,
                                nodeIndex = 0,
                                index;

                            for (var i = 0; i < numChildNodes; i++) {
                                if (childNodes[i].nodeType !== 1) {
                                    continue;
                                }

                                if (childNodes[i] === clickedListItem) {
                                    index = nodeIndex;
                                    break;
                                }
                                nodeIndex++;
                            }

                            if (index >= 0) {
                                openPhotoSwipe(index, clickedGallery);
                            }

                            return false;
                        };

                        var photoswipeParseHash = function () {
                            var hash = window.location.hash.substring(1),
                                params = {};

                            if (hash.length < 5) {
                                return params;
                            }

                            var vars = hash.split('&');
                            for (var i = 0; i < vars.length; i++) {
                                if (!vars[i]) {
                                    continue;
                                }
                                var pair = vars[i].split('=');
                                if (pair.length < 2) {
                                    continue;
                                }
                                params[pair[0]] = pair[1];
                            }

                            if (params.gid) {
                                params.gid = parseInt(params.gid, 10);
                            }

                            return params;
                        };

                        var galleryElements = document.querySelectorAll(gallerySelector);

                        for (var i = 0, l = galleryElements.length; i < l; i++) {
                            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
                            galleryElements[i].onclick = onThumbnailsClick;
                        }

                        var hashData = photoswipeParseHash();
                        if (hashData.pid && hashData.gid) {
                            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
                        }
                    };

                    initPhotoSwipeFromDOM('#photoswipe-gallery');
                }
            }
        },
        'page_template_template_developments_gallery': {
            init: function () {
                var initPhotoSwipeFromDOM = function (gallerySelector) {
                    var parseThumbnailElements = function (el) {
                        var thumbElements = el.childNodes,
                            numNodes = thumbElements.length,
                            items = [],
                            figureEl,
                            linkEl,
                            size,
                            item;

                        for (var i = 0; i < numNodes; i++) {
                            figureEl = thumbElements[i];

                            if (figureEl.nodeType !== 1) {
                                continue;
                            }

                            linkEl = figureEl.children[0];
                            var href = linkEl.getAttribute('href');

                            if (href.includes('youtube.com') || href.includes('youtu.be')) {
                                // Handle YouTube video
                                var iframeSrc = linkEl.getAttribute('data-iframe');
                                item = {
                                    html: '<div class="pswp__video-wrapper"><iframe src="' + iframeSrc + '" frameborder="0" allowfullscreen></iframe></div>',
                                    w: 1280,
                                    h: 720
                                };
                            } else {
                                // Handle image
                                size = linkEl.getAttribute('data-size').split('x');

                                item = {
                                    src: linkEl.getAttribute('href'),
                                    w: parseInt(size[0], 10),
                                    h: parseInt(size[1], 10)
                                };
                            }

                            if (figureEl.children.length > 1) {
                                item.title = figureEl.children[1].innerHTML;
                            }

                            if (linkEl.children.length > 0) {
                                item.msrc = linkEl.children[0].getAttribute('src');
                            }

                            item.el = figureEl;
                            items.push(item);
                        }

                        return items;
                    };

                    var closest = function closest(el, fn) {
                        return el && (fn(el) ? el : closest(el.parentNode, fn));
                    };

                    var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
                        var pswpElement = document.querySelectorAll('.pswp')[0],
                            gallery,
                            options,
                            items;

                        items = parseThumbnailElements(galleryElement);

                        options = {
                            closeEl: true,
                            captionEl: true,
                            fullscreenEl: true,
                            zoomEl: true,
                            shareEl: false,
                            counterEl: true,
                            arrowEl: true,
                            preloaderEl: true,
                            galleryUID: galleryElement.getAttribute('data-pswp-uid'),
                            getThumbBoundsFn: function (index) {
                                var thumbnail = items[index].el.getElementsByTagName('img')[0],
                                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                                    rect = thumbnail.getBoundingClientRect();

                                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                            }
                        };

                        if (fromURL) {
                            if (options.galleryPIDs) {
                                for (var j = 0; j < items.length; j++) {
                                    if (items[j].pid === index) {
                                        options.index = j;
                                        break;
                                    }
                                }
                            } else {
                                options.index = parseInt(index, 10) - 1;
                            }
                        } else {
                            options.index = parseInt(index, 10);
                        }

                        if (isNaN(options.index)) {
                            return;
                        }

                        if (disableAnimation) {
                            options.showAnimationDuration = 0;
                        }

                        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                        gallery.init();
                    };

                    var onThumbnailsClick = function (e) {
                        e = e || window.event;
                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

                        var eTarget = e.target || e.srcElement;

                        var clickedListItem = closest(eTarget, function (el) {
                            return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
                        });

                        if (!clickedListItem) {
                            return;
                        }

                        var clickedGallery = clickedListItem.parentNode,
                            childNodes = clickedListItem.parentNode.childNodes,
                            numChildNodes = childNodes.length,
                            nodeIndex = 0,
                            index;

                        for (var i = 0; i < numChildNodes; i++) {
                            if (childNodes[i].nodeType !== 1) {
                                continue;
                            }

                            if (childNodes[i] === clickedListItem) {
                                index = nodeIndex;
                                break;
                            }
                            nodeIndex++;
                        }

                        if (index >= 0) {
                            openPhotoSwipe(index, clickedGallery);
                        }

                        return false;
                    };

                    var photoswipeParseHash = function () {
                        var hash = window.location.hash.substring(1),
                            params = {};

                        if (hash.length < 5) {
                            return params;
                        }

                        var vars = hash.split('&');
                        for (var i = 0; i < vars.length; i++) {
                            if (!vars[i]) {
                                continue;
                            }
                            var pair = vars[i].split('=');
                            if (pair.length < 2) {
                                continue;
                            }
                            params[pair[0]] = pair[1];
                        }

                        if (params.gid) {
                            params.gid = parseInt(params.gid, 10);
                        }

                        return params;
                    };

                    var galleryElements = document.querySelectorAll(gallerySelector);

                    for (var i = 0, l = galleryElements.length; i < l; i++) {
                        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
                        galleryElements[i].onclick = onThumbnailsClick;
                    }

                    var hashData = photoswipeParseHash();
                    if (hashData.pid && hashData.gid) {
                        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
                    }
                };

                initPhotoSwipeFromDOM('#photoswipe-gallery');
                initPhotoSwipeFromDOM('#photoswipe-gallery-images');
                initPhotoSwipeFromDOM('#photoswipe-gallery-videos');
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Function to add to cart via AJAX
    function addToCart(productID, variationID, quantity) {
        $.ajax({
            url: ajaxObject.ajaxUrl,
            type: 'POST',
            data: {
                action: 'addVariationToCart',
                productID: productID,
                variationID: variationID,
                quantity: quantity
            },
            success: function (response) {
                $('.add-to-cart').prop('disabled', false);

                // Handle success (e.g., show a message)
                console.log(response);
            },
            error: function (xhr, status, error) {
                $('.add-to-cart').prop('disabled', false);

                // Handle errors
                console.error(status + ': ' + error);
            }
        });
    }

    // content-legends.php modal
    $('.content-legends-modal-activate').click(function () {
        const productID = $(this).data('product-id');

        const productName = 'product' + productID;

        let variationData = window[productName].information;

        // Build HTML content using the variation data
        let modalContent = '<div class="modal-content-container">';

        let counter = 0;
        $.each(variationData, function (index, details) {

            const buttonText = details.availability > 0 ? 'Add to Cart' : 'Sold Out';
            const buttonDisabled = details.availability > 0 ? '' : ' disabled';
            const variationID = details.variationId;

            if (counter === 0) {
                modalContent += '<div class="variation-top row">' +
                    '<div class="col-sm-12 heading">' +
                    '<p class="title">Next Match</p>' +
                    '<p class="date">' + details.dateTime + '</p>' +
                    '</div>' +
                    '<div class="col-xs-5 home-team">' +
                    '<div class="crest-wrapper"><div class="image-wrapper"><img class="crest" style="max-height: 200px" src="' + details.homeTeamCrest + '" alt="Team Crest"></div></div>' +
                    '<p class="team-name">' + details.homeTeam + '</p>' +
                    '</div>' +
                    '<div class="col-xs-2 vs">' +
                    '<p>VS</p>' +
                    '</div>' +
                    '<div class="col-xs-5 opposing-team">' +
                    '<div class="crest-wrapper"><div class="image-wrapper"><img class="crest" style="max-height: 200px" src="' + details.opposingTeamCrest + '" alt="Team Crest"></div></div>' +
                    '<p class="team-name">' + details.opposingTeam + '</p>' +
                    '</div>' +
                    '</div>' +
                    '<div class="variation-top-info row">' +
                    '<div class="col-sm-3 availability">' +
                    '<p class="title">Availability</p>' +
                    '<p class="information">' + details.availability + '</p>' +
                    '</div>' +
                    '<div class="col-sm-3 price">' +
                    '<p class="title">Price</p>' +
                    '<p class="information">' + details.price + '</p>' +
                    '</div>' +
                    '<div class="col-sm-3 quantity">' +
                    '<p class="title">Quantity</p>' +
                    '<input type="number" min="1" max="' + details.availability + '" value="1" class="quantity-input">' + // Input field for quantity
                    '</div>' +
                    '<div class="col-sm-3 buy-button">' +
                    '<button class="add-to-cart btn btn-primary" data-product-id="' + productID + '" data-variation-id="' + variationID + '"' + buttonDisabled + '>' + buttonText + '</button>' + // Add to Cart button with data attributes
                    '</div>' +
                    '</div>' +
                    '<div class="upcoming-matches row">' +
                    '<div class="col-sm-12 upcoming-title">' +
                    '<p>Upcoming</p>' +
                    '</div>';
                counter++;
            } else {
                modalContent += '<div class="col-sm-12 match-col-row">' +
                    '<div class="row match-row">' +
                    '<div class="col-sm-3 match">' +
                    '<p class="title">Match</p>' +
                    '<p class="information">' + details.game + '</p>' +
                    '</div>' +
                    '<div class="col-sm-3 date">' +
                    '<p class="title">Date</p>' +
                    '<p class="information">' + details.dateTime + '</p>' +
                    '</div>' +
                    '<div class="col-sm-2 availability">' +
                    '<p class="title">Availability</p>' +
                    '<p class="information">' + details.availability + '</p>' +
                    '</div>' +
                    '<div class="col-sm-1 price-wrapper">' +
                    '<p class="title">Price</p>' +
                    '<p class="information">' + details.price + '</p>' +
                    '</div>' +
                    '<div class="col-sm-1 quantity">' +
                    '<p class="title">Quantity</p>' +
                    '<input type="number" min="1" max="' + details.availability + '" value="1" class="quantity-input">' + // Input field for quantity
                    '</div>' +
                    '<div class="col-sm-2 buy-button">' +
                    '<button class="add-to-cart btn btn-primary" data-product-id="' + productID + '" data-variation-id="' + variationID + '"' + buttonDisabled + '>' + buttonText + '</button>' + // Add to Cart button with data attributes
                    '</div>' +
                    '</div>' +
                    '</div>';
            }
        });

        modalContent += '</div></div>';

        $('.modal-body').html(modalContent); // Set the HTML content
        $('#theLegendsModal').modal('show'); // Show the modal

        // Add-to-cart click function for buttons in the "variation-top-info" section
        $('.variation-top-info .add-to-cart').click(function () {
            $(this).prop('disabled', true);

            const productID = $(this).data('product-id');
            const variationID = $(this).data('variation-id');
            const quantity = $(this).closest('.variation-top-info').find('.quantity-input').val();

            // Call the function to add to cart with productID, variationID, and quantity
            addToCart(productID, variationID, quantity);
            $('#custom-cart-popup').show();
        });

        // Add-to-cart click function for buttons in the "Upcoming" section
        $('.upcoming-matches .add-to-cart').click(function () {
            $(this).prop('disabled', true);

            const productID = $(this).data('product-id');
            const variationID = $(this).data('variation-id');
            const quantity = $(this).closest('.match-row').find('.quantity-input').val();

            // Call the function to add to cart with productID, variationID, and quantity
            addToCart(productID, variationID, quantity);
            $('#custom-cart-popup').show();
        });

    });

    $('.add-to-cart-simple').click(function () {
        // Add-to-cart click function for buttons in the "Upcoming" section

        $('#custom-cart-popup').show();

        const productID = $(this).data('product-id');
        const variationID = 0;
        let quantity = 1;

        if ($('#quantity-' + productID).length > 0) {
            quantity = $('#quantity-' + productID).val();
        }

        // Call the function to add to cart with productID, variationID, and quantity
        addToCart(productID, variationID, quantity);
    });

    $('.close-cart-popup').on('click', function () {
        $('#custom-cart-popup').hide();
    });

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
